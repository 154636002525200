import logo from './logo.svg';
import './App.css';
import DevAgencyLanding from './DevAgencyLanding';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CaseStudies from "./CaseStudies";

function App() {
  return (


     <Router>
      <Routes>
        <Route path="/" element={<DevAgencyLanding />} />
        <Route path="/case-studies" element={<CaseStudies />} />
      </Routes>
    </Router>
  );
}

export default App;
