import logo from './logo.svg';
import './App.css';
import "./LandingPage.css";
function CaseStudies() {
  return (
    <div className="CaseStudies">
          
stuff
    </div>
  );
}

export default CaseStudies;
